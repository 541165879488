import ReactDOM from 'react-dom'; // Correct import for ReactDOM
import { useSelector } from 'react-redux';
import React, { FC, ReactNode, useMemo, useCallback, useState, useEffect } from 'react';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, SalmonWalletAdapter, SafePalWalletAdapter, TokenaryWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl, Transaction, SystemProgram, Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';
import { actions, utils, programs, NodeWallet, Connection } from '@metaplex/js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
require('@solana/wallet-adapter-react-ui/styles.css');

const RootComponent = () => {
  // you can use Mainnet, Devnet, or Testnet here
  const solNetwork = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network: solNetwork }),
      new SalmonWalletAdapter(),
      new SafePalWalletAdapter(),
      new TokenaryWalletAdapter()
    ],
    [solNetwork]
  );

  return (
    <React.StrictMode>
              <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets}>

      <Provider store={store}>
      <WalletModalProvider>

        <ReactRouter>
          <App />
        </ReactRouter>
        </WalletModalProvider>

      </Provider>
      </WalletProvider>

      </ConnectionProvider>

    </React.StrictMode>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById('root'));

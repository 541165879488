import { useSelector } from 'react-redux';

import React, {  useState, useEffect } from 'react';
// import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import {  useWallet } from '@solana/wallet-adapter-react';
import {  WalletMultiButton } from '@solana/wallet-adapter-react-ui';
// import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';

import { Connection, PublicKey, LAMPORTS_PER_SOL, SystemProgram } from '@solana/web3.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as web3 from '@solana/web3.js'; 

// import { SyntheticEvent, MouseEventHandler } from 'react';
// import { web } from 'webpack';
import { Buffer } from 'buffer';

// import bs58 from "bs58";
// import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';


// import { actions, utils, programs, NodeWallet, Connection } from '@metaplex/js';


// import classes from './Home.module.scss';
import { RootState } from '../../redux/store';

import { Service } from '../../services/general.services';
import { IBooks } from '../../models/IBooks';
import '../styles/main.b83d9144.css';
import '../styles/modal.css';
import '../styles/remixicon.css';
import '../styles/responsive.css';
import '../styles/style.css';
import Img1 from '../../assets/img1.png';
import Img2 from '../../assets/Solana_logo.png';
import Img3 from '../../assets/logo.png';
import Img4 from '../../assets/img4.png';
import Img6 from '../../assets/img6.png';
import Img7 from '../../assets/img7.png';
import qr from '../../assets/qr.png';
import pink from '../../assets/pink.png';
import how1 from '../../assets/how1.svg';
import how2 from '../../assets/how2.svg';
import how3 from '../../assets/how3.svg';
import telegram from '../../assets/telegram.png';
import twitter from '../../assets/twitter.png';
import axios from 'axios';

// // import { SendLegacyTransaction } from './SendSolForm';
// import { SendSolForm } from './SendSolForm';
require('@solana/wallet-adapter-react-ui/styles.css');
interface SendSolFormProps {
    inputValue: string;
}
const Home = (): JSX.Element => {

    // @ts-ignore
    window.Buffer = Buffer;
    // const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { user } = useSelector((state: RootState) => state.root.user);
    const [jsonData, setJsonData] = useState<GLfloat>(0.0);
    const errNotify = (msg: any) => toast('There is an error in your transaction', msg);
    const successNotify = (msg: any, token: any) => toast('You will receive ' + token);
    const limitError = () => toast('Minmum Amount you can buy is 0.5 Sol');

    const fetchData = async (publicKeyString: string) => {
        try {
          // Simulating an API call
          const response = await axios.get(`http://137.184.39.238:8009/fetch/${publicKeyString}`);
          
          // Simulating response time
          await new Promise(resolve => setTimeout(resolve, 1000));
  
          // Set JSON data in state
          setJsonData(response.data.value);
          console.log(response.data.value);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      

      const postData = async (key: string, value: any): Promise<void> => {
        try {
          // Construct the payload object from key and value
          const payload = {
            [key]: value
          };
      
          // Post the data to the API
          const response = await axios.post('http://137.184.39.238:8009/store/', payload);
      
          // Simulating response time
          await new Promise(resolve => setTimeout(resolve, 1000));
      
          // Log the response data (or handle it as needed)
          console.log('Data posted successfully:', response.data);
      
        } catch (error) {
          console.error('Error posting data:', error);
        }
      };

    const { email } = user;
    useEffect(() => {
        Service().then((response: IBooks) => {
            console.log('++++++++++>>>', response)
        }).catch((error) => console.log(error));
    }, [])

    const [inputValue, setInputValue] = useState<string>('');
    const [resultValue, setResultValue] = useState<string>('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setInputValue(value);
        if (parseFloat(value)) {
            // Perform your calculation here (multiply by 100)
            const result: number = parseFloat(value) * 10000;
            setResultValue(isNaN(result) ? '' : result.toFixed(2)); // Ensure result is rounded to 2 decimal places
        }
        else {
            limitError();
        }

    };
    // Define the end time here
    // const endTime: Date = new Date('2024-02-01T18:50:47Z);
    // interface TimeLeftType {
    //     hours: number;
    //     minutes: number;
    //     seconds: number;
    // }
    // const calculateTimeLeft = (): TimeLeftType => {
    //     const difference: number = endTime.getTime() - new Date().getTime();
    //     let timeLeft: TimeLeftType = {
    //         hours: 0,
    //         minutes: 0,
    //         seconds: 0
    //     };

    //     if (difference > 0) {
    //         timeLeft = {
    //             hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    //             minutes: Math.floor((difference / 1000 / 60) % 60),
    //             seconds: Math.floor((difference / 1000) % 60)
    //         };
    //     }

    //     return timeLeft;
    // };

    // const [timeLeft, setTimeLeft] = useState<TimeLeftType>(calculateTimeLeft());

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);

    //     return () => clearTimeout(timer);
    // }, [calculateTimeLeft]);

    // const endTime = new Date(new Date().getTime() + 48 * 60 * 60 * 1000);
    let endTime = new Date('2024-02-04T10:59:00Z');
    if (new Date() > endTime) {
        endTime.setHours(endTime.getHours() + 5);
    }
    interface TimeLeftType {
        hours: number;
        minutes: number;
        seconds: number;
    }

    // This function remains the same, calculates time left based on the current time
    const calculateTimeLeft = (): TimeLeftType => {
        const difference: number = endTime.getTime() - new Date().getTime();
        let timeLeft: TimeLeftType = {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (difference > 0) {
            const totalHours = Math.floor(difference / (1000 * 60 * 60));
            timeLeft = {
                hours: totalHours,
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }else {
            // If the timer has reached zero, add 5 hours to the end time and restart the timer
            endTime.setHours(endTime.getHours() + 5);
          }
        

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeftType>(calculateTimeLeft());

    useEffect(() => {
        // Update the timer every second
        const timerId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, .00000001);

        // Cleanup on component unmount
        return () => clearInterval(timerId);
    }, []);
    // custom logic 
    const [txSig, setTxSig] = useState<string>('');
    const connection = new web3.Connection(
        "https://solitary-long-film.solana-mainnet.quiknode.pro/a22e3df8453ebb64b14b75271244d9dbec9aeba4/",
        'confirmed',
    );
    // const { publicKey, sendTransaction } = useWallet();

    const link = () => txSig ? `https://solscan.io/tx/${txSig}` : '';

    const sendSol = async () => {
        // event.preventDefault();

        if (!connection || !publicKey) {
            console.error("Connection or public key is missing.");
            return;
        }
        const amountInput = inputValue;
        const amount = parseFloat(amountInput);
        const recipient = 'ARFP27LfXWYxza5LbyuMwNEHYiKGrLsMkohKmeGZfcCt'

        if (isNaN(amount) || amount <= 0) {
            console.error("Invalid amount.");
            return;
        }
        let blockhashObj = await connection.getLatestBlockhash();
        console.log(blockhashObj.blockhash)
        const transaction = new web3.Transaction();
        const recipientPubKey = new web3.PublicKey(recipient);

        const sendSolInstruction = web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: recipientPubKey,
            lamports: LAMPORTS_PER_SOL * amount
        });

        transaction.add(sendSolInstruction);
        transaction.feePayer = publicKey
        transaction.recentBlockhash = blockhashObj.blockhash;
        sendTransaction(transaction, connection)
            .then(sig => { 
                setTxSig(sig); 
                successNotify(sig, resultValue); 
                const publicKeyString = publicKey.toString();
                return postData(publicKeyString, resultValue); 
            })
            .catch(error => {
                errNotify(error);
            })
    };


    async function getTotalSOLSent(publicKeyStr: string) {
        
        const publicKey = new PublicKey(publicKeyStr);
        const recipientPublicKey = new PublicKey("ARFP27LfXWYxza5LbyuMwNEHYiKGrLsMkohKmeGZfcCt");
    
        let totalLamportsSent = 0;
    
        const signatures = await connection.getSignaturesForAddress(publicKey);
        
        for (let signatureInfo of signatures) {
            
            const signature = signatureInfo.signature;
            const confirmedTransaction = await connection.getParsedTransaction(signature, 'finalized');
            
            if (!confirmedTransaction || !confirmedTransaction.transaction) {
                console.log(`No confirmed transaction found for signature: ${signature}`);
                continue;
            }
    
            // Ensure transaction message and instructions are present
            const { message } = confirmedTransaction.transaction;
            if (!message || !message.instructions) {
                
                console.log(`No instructions found in transaction: ${signature}`);
                continue;
            }
            // console.log(message.instructions);
            for (let instruction of message.instructions) {
                // Check if instruction is ParsedInstruction by checking for the presence of the 'parsed' property
                if ('parsed' in instruction && instruction.parsed.type === "transfer") {
                    // Now TypeScript knows instruction is ParsedInstruction, so it allows access to .parsed
                    if ((instruction.parsed.info.destination === recipientPublicKey.toString()) && (instruction.parsed.info.source === publicKeyStr)) {
                        totalLamportsSent += instruction.parsed.info.lamports;
                        console.log(instruction.parsed);
                    }
                }
            }
        }
        const totalSOLSent = 10000 *totalLamportsSent / LAMPORTS_PER_SOL;
        console.log(totalSOLSent);
        return totalSOLSent;
    }


    useEffect(() => {
        const fetchFunds = async () => {
            const funds = await getTotalSOLSent(publicKey?.toString()?? "DkaebuMMXb77BWvfjH6NwnFKKir91uMyJciM2goJPTu8");
            setJsonData(funds);
        };
        fetchFunds();
      }, [publicKey]);


    async function getFundsRaised() {
        const recipient = 'ARFP27LfXWYxza5LbyuMwNEHYiKGrLsMkohKmeGZfcCt';
        const recipientPubKey = new web3.PublicKey(recipient);
        
        try {
          // Fetch the balance
          const balance = await connection.getBalance(recipientPubKey);
          
          // Convert the balance from lamports to SOL (1 SOL = 1,000,000,000 lamports)
          const balanceInSol = balance / web3.LAMPORTS_PER_SOL;
          
          return balanceInSol;
        } catch (error) {
          console.error('Error fetching balance:', error);
          return 0; // Or handle the error as appropriate
        }
      }

    const [fundsRaised, setFundsRaised] = useState(0);

    useEffect(() => {
    const fetchFunds = async () => {
        const funds = await getFundsRaised();
        setFundsRaised(funds);
    };

    fetchFunds();
    }, []);
    // function getFundsRaised() {
    //     // This could be a calculation or a fetch from an API
    //     const recipient = 'ARFP27LfXWYxza5LbyuMwNEHYiKGrLsMkohKmeGZfcCt'
    //     const recipientPubKey = new web3.PublicKey(recipient);

    //     return 999.00; // This is just an example value
    //   }

    return (
        <>
            <div id="root">
                <div className="App">
                    <div>
                        <header>
                            <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-light">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="#">
                                        <img src={Img3} alt="WIFANI Logo" /> WIFANI
                                    </a>
                                    <button aria-controls="navbar-right" type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="navbar-collapse collapse" id="navbar-right">
                                        <div className="ml-auto navbar-nav">
                                            <a href="#home" className="nav-link">Home</a>
                                            <a href="#how-buy" className="nav-link">HOW TO BUY</a>
                                            <a href="#about" className="nav-link">About us</a>
                                            <a href="#utilities" className="nav-link">Utility</a>
                                            <a href="#why" className="nav-link">Why WIFANI</a>
                                        </div>
                                    </div>
                                    <div className="d-flex mx-3" role="search">
                                        {/* <a href="#home" className="btn btn-orange centerr">Buy Now</a> */}
                                        <WalletMultiButton style={{ margin: '4px' }} />

                                    </div>
                                    {/* <a href="https://t.me/WIFANIonsolana" className="header-tele-ic" rel="noreferrer" target="_blank">
                                        <img src="telegram.png" alt="Telegram Icon" />
                                    </a> */}
                                </div>
                            </nav>
                        </header>
                        <div className="site-wraper">

                            <section className="banner-home" id="home">

                                <div className="container">
                                    <div className="row align-items-end">
                                        <div className="col-md-6 text-center align-self-center">
                                            <div className="banner-content"><img src={Img1} className="bg-block" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 ms-auto banner-right align-self-center">
                                            <div className="swap-box">
                                                <div className="timer-box">
                                                    <h4 className="text-center">Phase 1 Presale End</h4>

                                                    <ul className="row">
                                                        <li className="col-md-4">
                                                            <div className="timer-box-in">
                                                                <h4 id="hours">{timeLeft.hours}</h4><span>Hours</span>
                                                            </div>
                                                        </li>
                                                        <li className="col-md-4">
                                                            <div className="timer-box-in">
                                                                <h4 id="minutes">{timeLeft.minutes}</h4><span>Mins</span>
                                                            </div>
                                                        </li>
                                                        <li className="col-md-4">
                                                            <div className="timer-box-in">
                                                                <h4 id="seconds">{timeLeft.seconds}</h4><span>Sec</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr />
                                                <div className="swap-box-in">
                                                    <div className="swap-box-head">
                                                    {jsonData? <h4 className="text-center">Your Holdings: {jsonData} $WIFANI</h4> : <h4 className="text-center">Your Holdings: 0.00 $WIFANI</h4>}
                                                        <h4>Funds Raised: {fundsRaised.toFixed(2)}&nbsp;SOL</h4>
                                                    </div>
                                                    <div className="swap-form">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <div className="d-flex"><label>Amount in SOL You Pay:</label></div>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="number"
                                                                            id="inputNumber"
                                                                            placeholder="Min 0.5 SOL | Max 75 SOL"
                                                                            className="form-control"
                                                                            value={inputValue}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <div className="input-icon"><span><img src={Img2} alt="" /></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <div className="d-flex"><label>Amount in WIFANI You Receive:</label></div>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="number"
                                                                            id="result"
                                                                            name=""
                                                                            className="form-control"
                                                                            value={resultValue}
                                                                            readOnly // Make the result input read-only
                                                                        />
                                                                        <div className="input-icon"><span><img src={Img3} alt="" /></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="myModal" className="modal">
                                                            <div className="modal-content">
                                                                <span className="close-symbol" style={{ cursor: 'pointer', float: 'right' }}>&#x274C;</span>
                                                                {/* <h2 className="modal_heading">Connect your wallet</h2> */}

                                                                <div className="buttons-container">
                                                                    <button className="modal-button btn-primary" >
                                                                        <img src="phantom-logo.png" width="20" alt="Logo 1" className="button-logo" /> Phantom
                                                                    </button>
                                                                    <button className="modal-button btn-secondary" >
                                                                        <img src="solflare.png" width="20" alt="Logo 2" className="button-logo" /> Solflare
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wallet-adapter-dropdown">
                                                            <div style={{ display: 'flex', width: '80%', justifyContent: 'space-between', margin: 'auto' }}>

                                                                {/* {publicKey ? <button onClick={(event) => sendSol(event, inputValue)} className="wallet-adapter-button wallet-adapter-button-trigger" tabIndex={0} type="button" style={{ pointerEvents: 'auto', margin: '4px' }}>Transfer</button> : ''} */}




                                                                
                                                                {publicKey ? <button onClick={sendSol} className="wallet-adapter-button wallet-adapter-button-trigger" tabIndex={0} type="button" style={{ pointerEvents: 'auto', margin: '4px' }}>Transfer</button> : <WalletMultiButton style={{ margin: '4px' }}>Connect to Buy</WalletMultiButton>}

                                                                {/* <WalletMultiButton  onClick={onClick} /> */}
                                                                {/* <button onClick={onClick}>btn0</button> */}
                                                            </div>
                                                            {/* <button className="wallet-adapter-button wallet-adapter-button-trigger" tabIndex={0} type="button" style={{ pointerEvents: 'auto' }}>Select Wallet</button> */}
                                                            <ul aria-label="dropdown-list" className="wallet-adapter-dropdown-list false" role="menu">
                                                                <li className="wallet-adapter-dropdown-list-item" role="menuitem">Change wallet</li>
                                                            </ul>
                                                        </div>
                                                        <div className="swap-last-line text-center mt-2">
                                                            <p>Dont Prefer Wallet Connection? <a href="#qr-sec">Use Direct Transfer</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="why-section section-padding">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="heading-text">
                                                <h4>Presale in Progress </h4>
                                                <h4>Join WIFANI Now</h4>
                                                <p>WIFANI is currently in the presale phase, providing you with the exclusive opportunity to acquire WIFANI before the official launch. Immerse yourself in the WIFANI community now and secure your spot in the pack!</p>
                                                <div className="text-center mt-4"><a href="#home" className="btn btn-red centerr alterBtn">Buy Now</a></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ms-auto">
                                            <figure><img src={Img4} width="100%" alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div>
                            </div>
                            <section className="why-section section-padding py-5" id="qr-sec">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-md-4">
                                            <figure><img src={qr} width="100%" alt="" /></figure>
                                        </div>
                                        <div className="col-md-6 ms-auto">
                                            <div className="heading-text">
                                                <h4>Alternative Presale Approach</h4>
                                                <h4>SOL Transfer Directly</h4>
                                                <p>If you face challenges accessing the DApp or prefer a more direct method, participate in the $WIFANI presale by sending your chosen SOL amount directly to <br /><span style={{ color: 'rgb(255, 0, 194)', fontSize: '20px', fontWeight: '700' }} > HOTSALE.SOL </span> <br /> (Minimum 0.5 SOL, Maximum 75 SOL).</p>
                                                <div className="text-center mt-4"><a href="#home" className="btn btn-red centerr alterBtn">Buy Now</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="how-to-buy section-padding" id="how-buy">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="headings">
                                                <h4>How to Participate in the $WIFANI Presale</h4>
                                                <p>Are you ready to be a part of the WIFANI revolution? Joining the WIFANI Presale is a straightforward process. Follow these simple steps to become a part of the WIFANI community:</p><img src={pink} width="100%" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-5 ms-auto">
                                            <div className="steps-how-buy">
                                                <ul>
                                                    <li>
                                                        <div className="left-how">
                                                            <figure><img src={how1} alt="" /></figure>
                                                        </div>
                                                        <div className="right-how">
                                                            <h4>Solana Wallet</h4>
                                                            <p>Link your Solana wallet to effortlessly join the WIFANI adventure.</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="left-how">
                                                            <figure><img src={how2} alt="" /></figure>
                                                        </div>
                                                        <div className="right-how">
                                                            <h4>Choose Amount</h4>
                                                            <p>Take control and select the amount of WIFANI you desire. (Minimum 0.5 SOL | Maximum 75 SOL)</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="left-how">
                                                            <figure><img src={how3} alt="" /></figure>
                                                        </div>
                                                        <div className="right-how">
                                                            <h4>Click Buy Button</h4>
                                                            <p>After deciding your investment, click on the buy button, and confirm the transaction on your Phantom wallet..</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="about-section section-padding" id="about">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="heading-text">
                                                <h4>The Birth of WIFANI</h4><span className="divider"></span>
                                                <p>WIFANI was conceived with a vision to create a memecoin that breaks the mold. Inspired by the success of other dog-themed meme projects on Solana, WIFANI joins the pack with the mission of uniting dog lovers and crypto enthusiasts on this thrilling blockchain. The objective was to craft a lighthearted and interactive digital currency that not only brings humor but also meaningful utilities.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="heading-text">
                                                <h4>What is WIFANI</h4><span className="divider"></span>
                                                <p>WIFANI is a groundbreaking digital currency on the Solana blockchain. Engineered for simplicity, security, and accessibility, WIFANI provides a seamless crypto experience for both seasoned traders and curious beginners. As a memecoin, WIFANI symbolizes a movement to make Solana more inclusive and inviting for everyone.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="bg-wrap">
                                <section className="utility-sec section-padding" id="utilities">
                                    <div className="container">
                                        <div className="heading-text mb-5 text-center">
                                            <h4>WIFANI Utilities</h4>
                                        </div>
                                        <div className="row utility-box">
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>WIFANI Sniper</h4><span className="divider"></span>
                                                        <p>We are set to launch our Solana Sniper bot in 7 days.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>WIFANI Raffle</h4><span className="divider"></span>
                                                        <p>Engage in thrilling raffle events with WIFANI tokens.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>Voting</h4><span className="divider"></span>
                                                        <p>Participate in community decisions with the power of your WIFANI.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>Staking Pool</h4><span className="divider"></span>
                                                        <p>Earn rewards by staking your WIFANI tokens.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>WIFANI Bridge</h4><span className="divider"></span>
                                                        <p>Connect WIFANI with other blockchains for enhanced functionality.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                            <div className="col-md-4">
                                                <article>
                                                    <figcaption>
                                                        <h4>Mini Games</h4><span className="divider"></span>
                                                        <p>Have fun and earn WIFANI through entertaining mini-games.</p>
                                                    </figcaption>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="roshi-crypto">
                                    <div className="container">
                                        <div className="roshi-crypto-in">
                                            <h2>WIFANI</h2>
                                            <h4>More Than a Cryptocurrency</h4>
                                            <p>WIFANI believes in the potential of Solana to create positive change. It's not just a memecoin; it's a movement dedicated to making Solana more inclusive and welcoming. WIFANI aims to simplify the crypto experience, ensuring it is accessible to everyone.</p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <section className="why-section why-section-2 section-padding" id="why">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="heading-text mb-4">
                                                <h4>Why Choose WIFANI</h4>
                                            </div>
                                            <div className="list-why">
                                                <ul>
                                                    <li>
                                                        <h4>Join the Pack</h4>
                                                        <p>Become part of the memecoin movement on Solana.</p>
                                                    </li>
                                                    <li>
                                                        <h4>First on Solana with Utilities</h4>
                                                        <p>WIFANI stands out as the first memecoin on Solana with a range of utilities.</p>
                                                    </li>
                                                    <li>
                                                        <h4>Movement Uniting Dog Lovers and Crypto Enthusiasts</h4>
                                                        <p>WIFANI bridges the gap between the love for dogs and the world of cryptocurrency.</p>
                                                    </li>
                                                    <li>
                                                        <h4>Solana's Pumping Trend</h4>
                                                        <p>Solana's remarkable growth and low transaction fees make it the ideal chain for WIFANI.</p>
                                                    </li>
                                                    <li>
                                                        <h4>Other Dog Memes</h4>
                                                        <p>Some dog memes on Solana with multi-million market caps include $BONK, $MYRO, $WIF, $SAMO.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-3 ms-auto">
                                            <figure><img src={Img7} width="100%" alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <footer>
                            <div className="container">
                                <div className="footer-logo"><a href=""><img src={Img3} alt="" /></a>
                                    <div className="col-md-4 mt-2 social"><a href="https://t.me/wifanita" rel="noreferrer" target="_blank"><img src={telegram} alt="" /></a><a href="https://twitter.com/WIFANITA_sol" rel="noreferrer" target="_blank"><img src={twitter} className="twitter1" alt="" /></a></div>
                                </div>
                                <div className="foot-last text-center">Copyright © 2024 WIFANI. All Rights Reserved</div>
                            </div>
                        </footer>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}
export default Home;





